<template>
  <h4>Dashboard</h4>
</template>
<script>
export default {
  created() {
    this.$appInsights.trackPageView({
      name: "redirect-to-home",
      url: window.location.href
    });
  }
};
</script>
